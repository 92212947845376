"use client";
import DividerWithText from "@/components/ui/Divider/DividerWithText";
import Terms from "@/components/ui/Terms";
import Image from "next/image";
import { Dispatch, SetStateAction, useState } from "react";
import { useMagic } from "../../../context/MagicProvider";
import PrimaryButton from "../../ui/Forms/Button/PrimaryBtn";
import TextTitle from "../../ui/Typography/TextTitle";
import { BtnRainbowKit } from "./BtnRainbowKit";
import { handleMagicLogin } from "./handleMagicLogin";

type Props = {
  setLoginModal: Dispatch<SetStateAction<boolean>>;
};

const Login = ({ setLoginModal }: Props) => {
  const { magic } = useMagic();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoginInProgress, setLoginInProgress] = useState(false);

  return (
    <>
      <div className="mx-auto p-4 mt-8 mb-1 relative flex flex-col items-center justify-center">
        <div className="-z-10 bg-primary/70 blur-[32px] rounded-full absolute top-0 left-1/2 w-[90px] h-[50px]" />
        <Image
          src="/rodeo-logo.png"
          alt="Rodeyo Logo"
          width={58}
          height={42}
          className="w-[50px] h-auto object-contain mb-2"
        />
      </div>
      <TextTitle title="LOG IN" />
      <div className="h-[24px]" />
      <form
        className="grid gap-y-[24px] mt-[36x] mb-[12px]"
        onSubmit={(e) =>
          handleMagicLogin(
            e,
            setLoginModal,
            email,
            setEmail,
            setEmailError,
            setLoginInProgress,
            magic
          )
        }
      >
        <div>
          <input
            disabled={isLoginInProgress}
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              if (emailError) setEmailError(false);
              setEmail(e.target.value);
            }}
            placeholder="you@example.com"
            value={email}
            className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-3 
              text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
          />
        </div>

        {emailError && <span className="error">Enter a valid email</span>}

        <PrimaryButton
          type="submit"
          loading={isLoginInProgress}
          disabled={isLoginInProgress || !email || email.length === 0}
        >
          Log in / Sign up
        </PrimaryButton>
      </form>
      <DividerWithText text="or" />
      <BtnRainbowKit setLoginModal={setLoginModal} />
      <Terms clickText="Login / Sign up" />
      <div className="sm:pb-[32px]" />
    </>
  );
};

export default Login;
