"use client";
import { TNFT } from "@/types/typings";
import { Seaport } from "@opensea/seaport-js";
import { OrderWithCounter } from "@opensea/seaport-js/lib/types";
import { ethers } from "ethers";
import { Magic } from "magic-sdk";

export const convertSaleItemPrice = (price: number, currency: string) => {
  if (currency === "USDC") {
    return ethers.parseUnits(price.toString(), 6).toString();
  }
  return ethers.parseEther(price.toString()).toString();
};

export const revertSaleItemPrice = (
  price: number,
  currency: string,
  show_currency = true
) => {
  let newPrice;
  if (currency === "$") {
    newPrice = price;
  } else if (currency === "USDC") {
    newPrice = `${ethers.formatUnits(price.toString(), 6)}`;
  } else {
    newPrice = `${ethers.formatEther(price.toString())}`;
  }
  if (show_currency) {
    if (currency === "$") {
      return `${currency} ${newPrice}`;
    }
    return `${newPrice} ${currency}`;
  }
  return newPrice;
};

export const ItemType = {
  ERC721: 2,
  ERC1155: 3,
  DCENTRAL_ERC721: 2,
  DCENTRAL_ERC1155: 3,
};

// @ts-ignore
// const provider = new ethers.providers.Web3Provider(magic.rpcProvider);

// const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_API_KEY);

let _seaport; // Cache the instance to avoid reinitialization

export const bootstrapSeaport = async () => {
  if (window !== undefined) {
    console.log("Window object found");
    if (!_seaport) {
      const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_API_KEY!);
      const provider = new ethers.BrowserProvider(magic.rpcProvider);
      const signer = provider.getSigner();
      // @ts-ignore
      _seaport = new Seaport(signer); // Ensuring Seaport receives a Signer
      console.log("Provider methods:", Object.keys(provider));
    }
    return _seaport;
  } else {
    console.log("No window object found");
  }
};

export const fulfillSeaportTradeOrder = async (
  order: OrderWithCounter,
  buyer: string
) => {
  try {
    // @ts-ignore
    const { executeAllActions } = await bootstrapSeaport().fulfillOrder({
      order,
      accountAddress: buyer,
    });

    const res = await executeAllActions();
    return res;
  } catch (err) {
    console.log(err, "err fulfilling trade order");
    return null;
  }
};

export const createSeaportOrder = async (
  sellerAddress: string,
  price: number,
  currency: string,
  offerItem: any
  // recipientWallet?: string
) => {
  const orderParams = {
    offer: [offerItem],
    consideration: [
      {
        amount: convertSaleItemPrice(price, currency), // This must be evenly divisble by offer.amount
        // recipient: recipientWallet || sellerAddress,
        recipient: sellerAddress,
        ...(currency === "USDC" && {
          token:
            process.env.NEXT_PUBLIC_USDC_ADDRESS_ETHEREUM ||
            "0xb2AECfb0a4681DB52fCB3B63F9392F5E0eD6f0E7",
        }),
      },
    ],
    allowPartialFills: true,
    // fees: [{recipient: address, basisPoints}] // here, basisPoints = 10000 (100%), 5000 (50%), 100(1%), 250 (2.5%)
  };
  // @ts-ignore
  const { executeAllActions } = await bootstrapSeaport().createOrder(
    orderParams,
    sellerAddress
  );
  const order = await executeAllActions();
  // @ts-ignore
  const orderHash = bootstrapSeaport().getOrderHash(order.parameters);
  return { ...order, orderHash };
};

export const createSeaportTradeOrder = async (
  sellerAddress: string,
  offerItems: any[],
  considerations: any[]
) => {
  const orderParams = {
    offer: offerItems,
    consideration: considerations,
    allowPartialFills: false,
    // fees: [{recipient: address, basisPoints}] // here, basisPoints = 10000 (100%), 5000 (50%), 100(1%), 250 (2.5%)
  };
  // @ts-ignore
  const { executeAllActions } = await bootstrapSeaport().createOrder(
    orderParams,
    sellerAddress
  );
  const order = await executeAllActions();
  return order;
};

export const convertNftToSeaportOfferItem = (
  nft: TNFT,
  owner_address: string
) => {
  const { token_id, token_address, collection, ownerships } = nft;
  const { contract_type } = collection;
  const ownership = ownerships.find(
    (item) =>
      item?.wallet_address?.toLowerCase() === owner_address?.toLowerCase()
  );
  const offerItem = {
    itemType: ItemType[contract_type || "ERC1155"],
    token: token_address,
    identifier: token_id,
    amount: ownership.quantity.toString(),
  };
  return offerItem;
};

export const convertNftToSeaportConsiderationItem = (
  nft: TNFT,
  recipient: string,
  owner_address: string
) => {
  const { token_id, token_address, collection, ownerships } = nft;
  const { contract_type } = collection;
  const ownership = ownerships.find(
    (item) =>
      item?.wallet_address?.toLowerCase() === owner_address?.toLowerCase()
  );
  const considerationItem = {
    itemType: ItemType[contract_type || "ERC1155"],
    token: token_address,
    identifier: token_id,
    amount: ownership.quantity.toString(),
    recipient,
  };
  return considerationItem;
};

export const cancelSeaportOrder = async (order: OrderWithCounter) => {
  try {
    // @ts-ignore
    const { buildTransaction } = bootstrapSeaport().cancelOrders([
      order.parameters,
    ]);
    const res = await buildTransaction();
    return res;
  } catch (err) {
    console.log(err, "err");
    return null;
  }
};
