import React from "react";
import styles from "./style.module.scss";

type Props = {
  containerShape?: string;
};

const SkeletonLoader = ({ containerShape }: Props) => {
  return (
    <div
      className={`bg-gradient-to-r from-dark-gray to-white/10 relative overflow-hidden rounded-lg ${
        containerShape ? containerShape : "w-full h-[18px] "
      }`}
    >
      <div className={styles.shimmerWrapper}>
        <div className={`${styles.shimmer} ${styles.shimmer1}`}></div>
        <div className={`${styles.shimmer} ${styles.shimmer2}`}></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
