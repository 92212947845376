"use client";
import { Seaport } from "@opensea/seaport-js";

export function initializeSeaport(provider: any) {
  if (!provider) {
    console.error("Please connect your wallet to use this feature");
    // throw new Error("Please connect your wallet to use this feature");
    return null;
  }
  const seaport = new Seaport(provider);
  console.log("Seaport initialized", seaport);
  return seaport;
}
