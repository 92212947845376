import CopyToClipboard from "@/components/ui/CopyToClipboard";
import Modal from "@/components/ui/Modal";
import TextTitle from "@/components/ui/Typography/TextTitle";
import { shortenAddress } from "@/utils/common";
import Image from "next/image";
import { create } from "zustand";
import { logout } from "@/utils/common";
import { useDisconnect } from "wagmi";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { logoutUser } from "@/server/actions/user/auth/logout";
import LoadingDots from "@/components/ui/Loading/LoadingDots";
import { FiLogOut } from "react-icons/fi";

interface WalletModalState {
  open: boolean;
  setOpen: (walletModal: boolean) => void;
}

export const useWalletModalStore = create<WalletModalState>((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
}));

type Props = {
  address?: string;
  balance?: string;
  avatar?: string;
};

export default function RainbowKitWalletModal({
  address,
  balance,
  avatar,
}: Props) {
  const { open, setOpen } = useWalletModalStore();
  const router = useRouter();
  const [loggingOut, setLoggingOut] = useState(false);
  const { disconnect } = useDisconnect();
  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      router.push("/");
      disconnect();
      await logoutUser();
    } catch (error) {
    } finally {
      setLoggingOut(false);
    }
  };
  return (
    <>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          width="sm:w-[450px] w-full border border-dark-pri/80"
        >
          {avatar && avatar.length > 0 && (
            <Image
              src={avatar}
              alt="user avatar"
              width={80}
              height={80}
              className="rounded-full object-contain mx-auto my-8"
            />
          )}
          <div className="grid justify-center gap-y-4 mb-8 mt-8 text-2xl font-semibold">
            {balance && <div className="text-center">{balance}</div>}
            {address && (
              <div className="text-center flex items-center justify-center gap-x-2">
                {shortenAddress(address)}
                <CopyToClipboard text={address} />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 pb-6">
            <button className="rounded-xl bg-dark-default-1 p-4 flex items-center justify-center gap-x-2">
              Copy Address <CopyToClipboard text={address!} />
            </button>
            <button
              onClick={handleLogout}
              className="rounded-xl bg-dark-default-1 p-4 flex items-center justify-center gap-x-2"
            >
              {loggingOut ? (
                <LoadingDots />
              ) : (
                <>
                  Disconnect
                  <div
                    className={`cursor-pointer p-[6px] rounded-full bg-dark-pri/20`}
                  >
                    <FiLogOut />
                  </div>
                </>
              )}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
