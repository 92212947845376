import MediaWrangler from "../Media";

type Props = {
  nftImage: string;
  animationUrl: string;
};

export default function ChosenNft({ nftImage, animationUrl }: Props) {
  return (
    <div className="mt-6 relative flex rounded-md mx-auto justify-center">
      <div className="!max-h-[100px] rounded-md pointer-events-none">
        <MediaWrangler
          src={nftImage || animationUrl || ""}
          fallback={nftImage || ""}
          //   nftImageFileType={tokenImageFileType}
        />
      </div>
    </div>
  );
}
