import PrimaryButton from "@/components/ui/Forms/Button/PrimaryBtn";
import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import showToast from "@/utils/showToast";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useTransition } from "react";
import { useAccountEffect } from "wagmi";
import RainbowKitWalletModal, {
  useWalletModalStore,
} from "./RainbowKitWalletModal";
import { FaChevronDown } from "react-icons/fa";

type Props = {
  setLoginModal?: Dispatch<SetStateAction<boolean>>;
  avatar?: string;
};

export const BtnRainbowKit = ({ setLoginModal, avatar }: Props) => {
  const router = useRouter();

  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          useAccountEffect({
            onConnect(data) {
              setLoginModal && setLoginModal(false);
              (async () => {
                try {
                  await handleUserLogin({
                    walletAddress: data.address || account?.address,
                  });

                  router.refresh();
                } catch (error) {
                  console.error("Login error:", error);
                  showToast({ message: "Login failed", type: "error" });
                }
              })(); // Invoke the async function
            },
          });

          const { setOpen } = useWalletModalStore();

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <PrimaryButton
                      onClick={openConnectModal}
                      style="flex items-center justify-center gap-x-2 w-full"
                      type="button"
                      neon
                    >
                      <img
                        src="/wallets/metamask.png"
                        alt="metamask"
                        className="w-7 h-7 object-contain"
                      />
                      <img
                        src="/wallets/coinbase.png"
                        alt="metamask"
                        className="w-7 h-7 object-contain"
                      />
                      Connect Wallet
                    </PrimaryButton>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <button onClick={openChainModal} type="button">
                      Wrong network
                    </button>
                  );
                }
                return (
                  <>
                    <div style={{ display: "flex", gap: 12 }}>
                      <button
                        onClick={openChainModal}
                        style={{ display: "flex", alignItems: "center" }}
                        type="button"
                        className="bg-dark-default-1  py-2 px-3 rounded-md font-600 text-base"
                      >
                        {chain.hasIcon && (
                          <div
                            style={{
                              background: chain.iconBackground,
                              width: 18,
                              height: 18,
                              borderRadius: 999,
                              overflow: "hidden",
                              marginRight: 4,
                            }}
                          >
                            {chain.iconUrl && (
                              <img
                                alt={chain.name ?? "Chain icon"}
                                src={chain.iconUrl}
                                style={{ width: 18, height: 18 }}
                              />
                            )}
                          </div>
                        )}
                        {chain.name}
                      </button>

                      <button
                        onClick={() => setOpen(true)}
                        type="button"
                        className="bg-dark-default-1  py-2 pr-2 pl-3 rounded-md font-600 tracking-wide text-base flex items-center gap-x-2"
                      >
                        {account.displayBalance
                          ? `(${account.displayBalance}) `
                          : ""}
                        <span className="rounded-md ml-2 flex items-center gap-x-2">
                          {account.displayName} <FaChevronDown />
                        </span>
                      </button>
                    </div>
                    <RainbowKitWalletModal
                      address={account.address}
                      balance={account.displayBalance}
                      avatar={avatar}
                    />
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
};
