import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

type Props = {
  mustBeAfterToday?: boolean;
  selectedDate: Date | null;
  onDateChange?: (date: Date | null) => void;
  placeholder?: string;
};

const CustomDatePicker = ({
  mustBeAfterToday = false,
  selectedDate,
  onDateChange,
  placeholder,
}: Props) => {
  const [startDate, setStartDate] = useState(selectedDate || null);

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  };

  return (
    <div className="relative">
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        minDate={mustBeAfterToday ? addDays(new Date(), 0) : undefined}
        // dateFormat="yyyy/MM/dd"
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholder ? placeholder : "Select a date"}
        className="w-full flex-1 outline-none rounded-md border-2 border-white/20 
        bg-white/10 pl-4 py-3 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
        calendarClassName="bg-gray-900 text-white border border-gray-700 shadow-lg rounded-md"
        wrapperClassName="w-full"
        dayClassName={(date) =>
          "hover:bg-gray-700 hover:text-white focus:bg-blue-500 focus:text-white"
        }
        weekDayClassName={() => "text-gray-400"}
        monthClassName={(date) => "bg-gray-800 text-white"}
        popperClassName="shadow-lg"
        todayButton="Today"
        // todayButtonClassName="bg-gray-700 text-white hover:bg-gray-600"
      />
    </div>
  );
};

export default CustomDatePicker;
