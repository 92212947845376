import { Dispatch, FormEvent, SetStateAction } from "react";
import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import { saveUserInfo } from "@/utils/common";
import showToast from "@/utils/showToast";
import { RPCError, RPCErrorCode } from "magic-sdk";

export const handleMagicLogin = async (
  e: React.KeyboardEvent | React.MouseEvent | FormEvent<HTMLFormElement>,
  setLoginModal: Dispatch<SetStateAction<boolean>>,
  email: string,
  setEmail: Dispatch<SetStateAction<string>>,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  setLoginInProgress: Dispatch<SetStateAction<boolean>>,
  magic: any
) => {
  e?.preventDefault();
  if (
    !email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    setEmailError(true);
  } else {
    try {
      setLoginInProgress(true);
      setEmailError(false);

      const token = await magic?.auth.loginWithEmailOTP({ email });
      const metadata = await magic?.user.getInfo();

      if (!token || !metadata?.publicAddress) {
        throw new Error("Magic login failed");
      }
      saveUserInfo(token, "EMAIL", metadata?.publicAddress);
      await handleUserLogin({ didToken: token || "", email });
      setLoginModal(false);
      setEmail("");
    } catch (e) {
      if (e instanceof RPCError) {
        switch (e.code) {
          case RPCErrorCode.MagicLinkFailedVerification:
          case RPCErrorCode.MagicLinkExpired:
          case RPCErrorCode.MagicLinkRateLimited:
          case RPCErrorCode.UserAlreadyLoggedIn:
            showToast({ message: e.message, type: "error" });
            break;
          default:
            showToast({
              message: "Something went wrong. Please try again",
              type: "error",
            });
        }
      }
    } finally {
      setLoginInProgress(false);
    }
  }
};
