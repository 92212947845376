"use client";
import SecondaryButton from "@/components/ui/Forms/Button/SecondaryBtn";
import IconCloseX from "@/components/ui/Icons/IconCloseX";
import React from "react";
import { create } from "zustand";

interface SellSuccessBannerState {
  openSellSuccessBanner: boolean;
  setOpenSellSuccessBanner: (openSellSuccessBanner: boolean) => void;
  modalNetwork: string;
  setModalNetwork: (modalNetwork: string) => void;
  modalAddress: string;
  setModalAddress: (modaAaddress: string) => void;
  modalTokenId: string;
  setModalTokenId: (modalTokenId: string) => void;
}

export const useBannerSellSuccessStore = create<SellSuccessBannerState>(
  (set) => ({
    openSellSuccessBanner: false,
    setOpenSellSuccessBanner: (openSellSuccessBanner) =>
      set({ openSellSuccessBanner }),
    modalNetwork: "",
    setModalNetwork: (modalNetwork) => set({ modalNetwork }),
    modalAddress: "",
    setModalAddress: (modalAddress) => set({ modalAddress }),
    modalTokenId: "",
    setModalTokenId: (modalTokenId) => set({ modalTokenId }),
  })
);

export default function BannerSellSuccess() {
  const {
    openSellSuccessBanner,
    setOpenSellSuccessBanner,
    modalNetwork,
    modalAddress,
    modalTokenId,
  } = useBannerSellSuccessStore();

  const handleClick = () => {
    setOpenSellSuccessBanner(false);
    window.open(
      `/nft/${modalNetwork}/${modalAddress}/${modalTokenId}`,
      "_self"
    );
  };
  return (
    <>
      {openSellSuccessBanner && (
        <div
          style={{
            zIndex: 9999,
          }}
          className="h-[220px] w-full lg:h-[120px] shadow-xl sticky top-0 left-0 flex p-3 lg:p-6 items-center justify-center bg-gradient-to-l from-dark-pri to-primary via-primary border-dark-pri backdrop-blur-lg"
        >
          <div className="flex items-center justify-center w-full gap-4">
            <img
              src="/memes/success-kid.png"
              className="w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] lg:min-w-[70px] lg:min-h-[70px] rounded-xl object-cover shadow-xl shadow-black/30"
            />
            <div className="grid w-auto justify-center mt-4 ">
              <div className="text-left font-500 text-lg tracking-wide">
                Success!
              </div>
              <div className="mt-2 lg:-mt-2 grid gap-4 lg:gap-6 md:flex items-center gap-x-6 w-full ">
                <div className="text-[16px]">
                  Your for-sale offer was successfully created.
                </div>{" "}
                <div className="lg:w-[230px]">
                  <SecondaryButton onClick={handleClick}>
                    View Listing
                    {/* <MdOutlineRefresh className="ml-1 text-lg" /> */}
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handleClick}
            className="absolute top-6 right-6 rounded-md p-1 bg-white/10 hover:bg-white hover:text-primary transition"
          >
            <IconCloseX />
          </button>
        </div>
      )}
    </>
  );
}
