import { Redis } from "@upstash/redis";

// export const redis = Redis.fromEnv();
export const redis = new Redis({
  //   url: process.env.UPSTASH_REDIS_REST_URL,
  url: "https://us1-honest-eft-41360.upstash.io",
  //   token: process.env.UPSTASH_REDIS_REST_TOKEN,
  token:
    "AaGQASQgM2EzMzNiNzMtY2NkOS00ODU4LWFjN2UtZDJlYmIxMzZiNGRhMmMwZDE2MzhiZGJmNDY1ZGE4MzI3NzE3NThiZjU4ZWM=",
});
