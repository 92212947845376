import { revertSaleItemPrice } from "@/services/seaport-client";
import { TFormatNFT, TNFT } from "@/types/typings";

const PUBLIC_NFT_PATH = process.env.NEXT_PUBLIC_ASSET_BASE_URL + "/";

export const getNftAssetPath = (imagePath: string, width?: string) => {
  if (!imagePath) return "";

  let assetUrl = imagePath;
  if (!assetUrl?.includes("https")) {
    assetUrl = PUBLIC_NFT_PATH + imagePath;
  }
  if (width && assetUrl?.includes(".dcentral.me"))
    assetUrl = `${assetUrl}?w=${width}`;
  return assetUrl;
};

export const getNetworkImage = (network: string) => {
  const lowerCaseNetwork = network?.toLowerCase();

  if (lowerCaseNetwork?.includes("ethereum")) {
    return "/network/1.png";
  } else if (lowerCaseNetwork?.includes("polygon")) {
    if (lowerCaseNetwork?.includes("zkevm")) {
      return "/network/1101.png";
    }
    return "/network/137.png";
  } else if (lowerCaseNetwork?.includes("arbitrum")) {
    return "/network/42161.png";
  } else if (lowerCaseNetwork?.includes("optimism")) {
    return "/network/10.png";
  } else if (lowerCaseNetwork?.includes("base")) {
    return "/network/8453.png";
  } else if (lowerCaseNetwork?.includes("starknet")) {
    return "/network/starknet.png";
  } else {
    return "/network/1.png";
  }
};

export const formatNFT = (NFT: TNFT): TFormatNFT => {
  let { image, image_data, image_url, attributes = [], medias } = NFT;

  let protocol = image && image.substr(0, 4);

  if (!image && medias?.length) {
    image = medias[0];
  }

  if (!image && image_data) {
    image = image_data;
  }

  if (!image) {
    if (medias?.length) {
      image = medias[0];
    } else if (image_data) {
      image = image_data;
    } else if (image_url) {
      image = image_url;
    } else {
      image = "";
    }
  }
  let rarity = "";

  if (Array.isArray(attributes)) {
    attributes.forEach(({ trait_type, value }) => {
      if (trait_type === "Rarity") {
        rarity = value;
      }
    });
  }

  if (protocol === "ipfs") {
    image = `https://ipfs.io/ipfs/${image.substr(7).replace("ipfs/", "")}`;
    protocol = image && image.substr(0, 4);
  }

  const isProtocolValid = protocol === "http";

  const isNameValid = !!NFT?.name;
  const isImageValid = isProtocolValid && /jpg|png|gif/.test(image.substr(-3));

  const saleOffers =
    NFT.ownerships
      ?.filter(
        ({ offer }) =>
          // offer?.offer_type === "buy_now" && offer?.state === OfferState.ACTIVE
          offer?.offer_type === "buy_now" && offer?.state === true
      )
      .map(({ offer }) => offer)
      .sort((a, b) =>
        !a.buy_now ? 1 : !b.buy_now ? -1 : a.buy_now.price - b.buy_now.price
      ) || [];

  const auctionOffers =
    NFT.ownerships
      ?.filter(
        ({ offer }) =>
          // offer?.offer_type === "auction" && offer?.state === OfferState.ACTIVE
          offer?.offer_type === "auction" && offer?.state === true
      )
      .map(({ offer }) => offer)
      .sort((a, b) =>
        !a.auction
          ? 1
          : !b.auction
          ? -1
          : a.auction.initial_price - b.auction.initial_price
      ) || [];
  const rentOffers =
    NFT.ownerships
      ?.filter(
        ({ offer }) => offer?.offer_type === "rent" && offer?.state === true
      )
      .map(({ offer }) => offer)
      .sort((a, b) =>
        !a.rent ? 1 : !b.rent ? -1 : a.rent.price - b.rent.price
      ) || [];

  let price: any =
    saleOffers?.[0]?.buy_now.price ||
    auctionOffers?.[0]?.auction.initial_price ||
    rentOffers?.[0]?.rent.price ||
    null;
  const currency = saleOffers?.[0]?.buy_now.currency || "";
  if (price) {
    price = revertSaleItemPrice(price, currency);
  }

  return {
    ...NFT,
    price,
    image,
    isImageValid,
    isNameValid,
    saleOffers,
    auctionOffers,
    rentOffers,
    rarity,
  };
};

// Capitalize the first letter of each word
export const capitalizeWords = (str) =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
