export const imageFileTypes = [
  "avif",
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "svg",
  "webp",
  "heic",
  "cr2", // Canon RAW
  "nef", // Nikon RAW
  "arw", // Sony RAW
];

export const videoFileTypes = [
  "mp4",
  "avi",
  "mkv",
  "mov",
  "wmv",
  "flv",
  "webm",
];

export const audioFileTypes = [
  "mp3",
  "wav",
  "aac",
  "flac",
  "ogg",
  "wma",
  "m4a",
];

export const modelFileTypes = [
  "obj",
  "fbx",
  "stl",
  "dae",
  "3ds",
  "blend",
  "ply",
  "glb",
  "gltf",
  "ma",
  "mb",
  "usd",
  "usdz",
];
