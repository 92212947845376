"use client";
import SecondaryButton from "@/components/ui/Forms/Button/SecondaryBtn";
import IconCloseX from "@/components/ui/Icons/IconCloseX";
import { useMagic } from "@/context/MagicProvider";
import { create } from "zustand";

interface TopUpBannerState {
  openTopUpBanner: boolean;
  setSetTopUpBanner: (openTopUpBanner: boolean) => void;
}

export const useTopUpStore = create<TopUpBannerState>((set) => ({
  openTopUpBanner: false,
  setSetTopUpBanner: (openTopUpBanner) => set({ openTopUpBanner }),
}));

export default function BannerTopUpFunds() {
  const { openTopUpBanner, setSetTopUpBanner } = useTopUpStore();

  const { magic } = useMagic();

  const handleClick = () => {
    setSetTopUpBanner(false);
    magic?.wallet.showUI();
  };
  return (
    <>
      {openTopUpBanner && (
        <div
          style={{
            zIndex: 9999,
          }}
          className="h-[220px] lg:h-[120px] shadow-xl  w-full sticky top-0 left-0 flex p-3 lg:p-6 items-center 
          justify-center bg-gradient-to-br from-dark-pri via-primary to-primary"
        >
          <div className="flex items-center justify-center w-full gap-4">
            <div className="grid w-auto justify-center mt-4 ">
              <div className="text-left font-500 text-lg tracking-wide max-w-[80%] ">
                Your wallet’s runnin a bit dry, partner.
              </div>
              <div className="mt-2  md:mt-0 grid gap-4 lg:gap-6 md:flex items-center gap-x-6 w-full ">
                <div className="text-[16px]">
                  Add funds to your wallet to purchase this.
                </div>{" "}
                <div className="lg:w-[230px]">
                  <SecondaryButton onClick={handleClick}>
                    Top Up Wallet{" "}
                    <span aria-hidden="true" className="ml-2">
                      &rarr;
                    </span>
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setSetTopUpBanner(false)}
            className="absolute top-6 right-6 rounded-md p-1 bg-white/10 hover:bg-white hover:text-primary transition"
          >
            <IconCloseX />
          </button>
        </div>
      )}
    </>
  );
}
