"use client";

import PrimaryButton from "@/components/ui/Forms/Button/PrimaryBtn";
import LoadingDots from "@/components/ui/Loading/LoadingDots";
import { useMagic } from "@/context/MagicProvider";
import { getUsdRate } from "@/utils/currency";
import { createSeaportListing } from "@/utils/seaport/sell";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ethers } from "ethers";
import debounce from "lodash/debounce";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useBannerSellErrorStore } from "@/app/nft/[network]/[address]/[tokenId]/(components)/Sell/Alerts/BannerSellError";
import { useBannerSellSuccessStore } from "@/app/nft/[network]/[address]/[tokenId]/(components)/Sell/Alerts/BannerSellSuccess";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  NETWORKS,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { getCachedPriceData } from "@/server/actions/currency";
import { create } from "zustand";
import CustomDatePicker from "../DatePicker";
import IconBack from "../Icons/IconBack";
import ChosenNft from "./ChosenNft";
import { addMonths } from "date-fns";

interface SellModalState {
  open: boolean;
  setOpen: (sellModal: boolean) => void;
}

export const useSellModalStateStore = create<SellModalState>((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
}));

type Props = {
  userWallet: string;
  tokenNetwork: string;
  collectionAddress: string;
  tokenId: string;
  tokenErc: string;
  setStep?: Dispatch<SetStateAction<number>>;
  nftName?: string;
  nftImage?: string;
  collectionName?: string;
  collectionImage?: string;
  showBackBtn?: boolean;
  setLocalSellModal?: Dispatch<SetStateAction<boolean>>;
};

export default function SellForm({
  userWallet,
  tokenNetwork,
  collectionAddress,
  tokenId,
  tokenErc,
  collectionName,
  collectionImage,
  setStep,
  nftName,
  nftImage,
  showBackBtn = true,
  setLocalSellModal,
}: Props) {
  const { magic, currentChainId, switchNetwork } = useMagic();
  const [priceConversionsToUsd, setPriceConversionsToUsd] = useState<
    any | null
  >(null);

  const { open, setOpen } = useSellModalStateStore();
  // Set chainID by network name
  const getChainIdByNetwork = (network: string) => {
    switch (network) {
      case "ethereum":
        return ETHEREUM_MAINNET.chainId;
      case "polygon":
        return POLYGON_MAINNET.chainId;
      case "optimism":
        return OPTIMISM_MAINNET.chainId;
      case "arbitrum":
        return ARBITRUM_MAINNET.chainId;
      case "base":
        return BASE_MAINNET.chainId;
      default:
        return ETHEREUM_MAINNET.chainId;
    }
  };

  useEffect(() => {
    const targetChain = getChainIdByNetwork(tokenNetwork);
    if (currentChainId !== targetChain) {
      const findNetworkChainId = NETWORKS.find(
        (n) => n.name === tokenNetwork
      )?.chainId;
      const targetNetworkRPC = NETWORKS.find(
        (n) => n.name === tokenNetwork
      )?.rpcUrl;

      switchNetwork(targetNetworkRPC!, findNetworkChainId!);
    }
  }, [tokenNetwork]);

  useEffect(() => {
    console.log("Token Network: ", tokenNetwork);
    const getCachedPrices = async () => {
      const pricesConversions = await getCachedPriceData();
      setPriceConversionsToUsd(pricesConversions);
    };
    getCachedPrices();
  }, []);

  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState<ethers.BrowserProvider | null>(null);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    setOpenSellSuccessBanner,
    setModalNetwork,
    setModalAddress,
    setModalTokenId,
  } = useBannerSellSuccessStore();

  const { setSellErrorBanner } = useBannerSellErrorStore();

  const [cryptoCurrency, setCryptoCurrency] = useState("");

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        if (magic) {
          // @ts-ignore
          const magicProvider = await magic.wallet.getProvider();
          const ethersProvider = new ethers.BrowserProvider(magicProvider);
          setProvider(ethersProvider);
        }
      } catch (error) {
        console.error("Error setting provider:", error);
      }
    };

    fetchProvider();
  }, [magic, currentChainId]);

  useEffect(() => {
    if (currentChainId === ETHEREUM_MAINNET.chainId) {
      setCryptoCurrency("ETH");
    } else if (currentChainId === POLYGON_MAINNET.chainId) {
      setCryptoCurrency("POL");
    } else if (currentChainId === OPTIMISM_MAINNET.chainId) {
      setCryptoCurrency("OP");
    } else if (currentChainId === ARBITRUM_MAINNET.chainId) {
      setCryptoCurrency("ARB");
    } else if (currentChainId === BASE_MAINNET.chainId) {
      setCryptoCurrency("ETH");
    }
  }, [currentChainId]);

  let today = new Date();
  let sixMonthsFromToday = addMonths(today, 6);

  const [startDate, setStartDate] = useState<Date>(today);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const [endDate, setEndDate] = useState<Date>(sixMonthsFromToday);
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSell = async () => {
    if (!price || price === "" || isNaN(parseFloat(price))) {
      setErrorMsg("Please enter a valid price.");
      return;
    }
    setLoading(true);
    try {
      if (!userWallet) {
        console.error("Failed to retrieve user address");
        return;
      }

      // Convert selectedStartDate and selectedEndDate to Unix timestamps
      const startTimestamp = BigInt(Math.floor(startDate.getTime() / 1000));
      const endTimestamp = BigInt(Math.floor(endDate.getTime() / 1000));

      const seaportRes = await createSeaportListing(
        provider!,
        userWallet,
        tokenNetwork,
        collectionAddress,
        tokenId,
        tokenErc!,
        price,
        cryptoCurrency,
        startTimestamp,
        endTimestamp
      );

      if (seaportRes) {
        setModalNetwork(tokenNetwork);
        setModalAddress(collectionAddress);
        setModalTokenId(tokenId);
        setOpenSellSuccessBanner(true);
        setLocalSellModal && setLocalSellModal(false);
      }
    } catch (error) {
      console.error("ERROR during offer listing creation:", error);
      setSellErrorBanner(true);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const [price, setPrice] = useState("");
  const [usdPrice, setUsdPrice] = useState<number | null>(null);
  useEffect(() => {
    if (price && price !== "" && !isNaN(parseFloat(price))) {
      setErrorMsg("");
    }
  }, [price]);
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validPrice = /^[0-9]*\.?[0-9]*$/;
    if (validPrice.test(value)) {
      setPrice(value);
    }
  };

  const updateUsdPrice = useCallback(
    debounce(async (price: string) => {
      if (!price || isNaN(parseFloat(price))) {
        setUsdPrice(null);
        return;
      }
      try {
        setFetchingPrice(true);

        const usdRateForCryptoCurrency = await getUsdRate(
          cryptoCurrency,
          priceConversionsToUsd
        );

        console.log(
          "USD rate for",
          cryptoCurrency,
          ":",
          usdRateForCryptoCurrency
        );

        const priceAsNumber = parseFloat(price);

        const priceInUSD = priceAsNumber * usdRateForCryptoCurrency;
        setUsdPrice(priceInUSD);
      } catch (error) {
        console.error("Error updating USD price:", error);
        setUsdPrice(null);
      } finally {
        setFetchingPrice(false);
      }
    }, 500),
    [priceConversionsToUsd, cryptoCurrency]
  );

  useEffect(() => {
    updateUsdPrice(price);
    return () => {
      updateUsdPrice.cancel();
    };
  }, [price, updateUsdPrice]);

  const formatUsdNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [fetchingPrice, setFetchingPrice] = useState(false);

  const handleBack = (e) => {
    e.preventDefault;
    // @ts-ignore
    showBackBtn && setStep(1);
  };

  return (
    <>
      {showBackBtn ? (
        <div className="absolute top-6 left-8">
          <button
            onClick={(e) => handleBack(e)}
            className="text-light-gray hover:text-dark-pri transition my-2 flex items-center gap-x-1 text-sm"
          >
            <IconBack />
            Back
          </button>
        </div>
      ) : (
        <div className="max-w-[90%] mx-auto text-center lg:max-w-[360px]">
          <div className="">
            <div className="mt-5 justify-center text-center text-3xl font-800 md:text-[28px] flex items-center gap-x-2">
              <span className="from-white to-light-gray bg-clip-text bg-gradient-to-r text-transparent leading-[40px]">
                {`Sell ${nftName ? nftName : `# ${tokenId}`}`}
              </span>
            </div>
          </div>
        </div>
      )}

      <form className="px-4 pb-2 mb-2 overflow-y-scroll scrollbar-hide">
        <div className="grid gap-y-0 max-w-[450px] mx-auto justify-center">
          <ChosenNft nftImage={nftImage || ""} animationUrl={nftImage || ""} />

          {/* Listing Terms */}
          <div className="w-full h-full flex flex-col gap-y-2">
            {/* Price */}
            <div className="grid gap-y-3">
              <div className=" font-500 grid gap-1">
                <span className="text-light-gray">Price </span>
                {price && usdPrice !== null && !isNaN(usdPrice) && (
                  <div className="text-white/80 text-sm">
                    {fetchingPrice ? (
                      <LoadingDots />
                    ) : (
                      <>
                        {price} {cryptoCurrency} = approximately{" "}
                        <span className="text-white">
                          {formatUsdNumber.format(usdPrice)}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="w-full relative">
                <input
                  id="listing-price"
                  placeholder={`Enter price in ${cryptoCurrency}`}
                  onChange={handlePriceChange}
                  value={price}
                  disabled={loading}
                  autoFocus={true}
                  className="w-full flex-1 outline-none rounded-md border-2 border-white/20 
              bg-white/10 pl-4 py-3 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
                />
                <div className="flex items-center gap-x-1 absolute right-4 top-1/2 -translate-y-1/2">
                  {cryptoCurrency}
                </div>
              </div>
            </div>
            {/* Date */}
            <div className="grid gap-y-2 my-3">
              <span className="font-500 text-light-gray">Dates</span>
              <div className="grid md:grid-cols-9 gap-x-5 gap-y-4">
                <div className="md:col-span-4">
                  <CustomDatePicker
                    mustBeAfterToday={true}
                    selectedDate={startDate}
                    onDateChange={handleStartDateChange}
                    placeholder="Start Sale on ..."
                  />
                </div>
                <span className="text-light-gray flex items-center justify-center text-center text-sm">
                  To
                </span>
                <div className="md:col-span-4">
                  <CustomDatePicker
                    mustBeAfterToday={true}
                    selectedDate={endDate}
                    onDateChange={handleEndDateChange}
                    placeholder="End sale on ..."
                  />
                </div>
              </div>
            </div>
            <div>
              {errorMsg && errorMsg?.length > 0 && (
                <div className="text-pink pb-3 flex items-center gap-x-2">
                  <ExclamationTriangleIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                  {errorMsg}
                </div>
              )}
              <PrimaryButton
                onClick={handleSell}
                loading={loading}
                disabled={!provider}
                style="w-full !uppercase mt-1"
                neon
              >
                List for Sale
              </PrimaryButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
